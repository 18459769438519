import { useParams } from "react-router-dom";

import { S } from "./dataSourceDrawer.styles";
import {
    AllocationNoteField,
    PropertyAllocationField,
    OwnershipField,
} from "./DrawerFields";
import { AutomaticSourceRowData } from "../automaticSourcesTable.types";

type Props = {
    data: AutomaticSourceRowData;
};

export const AllocationDetailsPanel = (props: Props) => {
    const { data } = props;

    const { locationId } = useParams();

    return (
        <S.Panel>
            <PropertyAllocationField
                id={data.integrationId}
                assetName={data.assetName}
                assetId={locationId ?? ""}
            />

            <OwnershipField
                id={data.integrationId}
                landlordOwnership={data.landlordOwnership ?? 0}
                tenantOwnership={data.tenantOwnership ?? 0}
                otherOwnership={data.otherOwnership ?? 0}
            />

            <AllocationNoteField
                id={data.integrationId}
                note={data.allocationNote ?? ""}
            />
        </S.Panel>
    );
};
