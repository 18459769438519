import { Box, InputLabel, styled, Typography } from "@mui/material";

export const S = {
    Box: styled(Box)(() => ({
        gap: "8px 0",
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-between",
        alignItems: "center",
    })),
    Header: styled(Box)(() => ({
        display: "flex",
        alignItems: "center",
        gap: 2,
    })),
    ButtonBox: styled(Box)(({ theme }) => ({
        display: "flex",
        gap: theme.spacing(2),
    })),
    BorderBox: styled(Box)(({ theme }) => ({
        width: "100%",
        border: "1px solid #E0E0E0",
    })),
    FieldBox: styled(Box)(({ theme }) => ({
        height: 58,
        display: "grid",
        gap: "8px 0",
        padding: "8px 16px",
        gridTemplateColumns: "1fr 30%",

        borderBottom: "1px solid #E0E0E0",
        "&:last-child": {
            borderBottom: "none",
        },
    })),
    ListItem: styled(Box)(({ theme }) => ({
        display: "flex",
        alignItems: "center",
        gap: theme.spacing(1),
        padding: theme.spacing(2, 4),
        justifyContent: "space-between",

        borderBottom: "1px solid #E0E0E0",
        "&:last-child": {
            borderBottom: "none",
        },
    })),
    ListItemText: styled(Typography)({
        display: "flex",
        alignItems: "center",
        gap: 4,
    }),
    NumberText: styled(Typography)({
        fontWeight: "bold",
        color: "blue",
    }),
    InputLabel: styled(InputLabel, {
        shouldForwardProp: (prop) => prop !== "bold",
    })<{ bold: boolean }>(({ bold }) => ({
        alignItems: "center",
        display: "flex",
        color: bold ? "black" : "inherit",
        fontWeight: bold ? "bold" : "normal",
    })),
};
