import { flatPropertyTree } from "containers/AssetPage/AssetDetails/components";
import {
    AllocationObjectType,
    AssetIntegrationAllocation,
    AssetPropertyTree,
} from "graphql-types/graphql";

import { EditItem } from "./EditableFieldList";

type OwnershipItem = {
    label: string;
    value: number;
    isChecked: boolean;
};

export const ownershipsEquals100 = (ownership: OwnershipItem[]) => {
    const totalOwnership = ownership
        .filter((item) => item.isChecked)
        .reduce((sum, item) => sum + item.value, 0);

    return totalOwnership === 100;
};

export type PropertyAllocationEditItem = Required<EditItem> & {
    objectType: AllocationObjectType;
};

export type PropertyAllocationItem = {
    id: string;
    depth: number;
    name: string;
    objectType: AllocationObjectType;
    allocation?: number;
};

export const getAllocationPropertyTree = (
    propertyTree: AssetPropertyTree[],
    allocations: AssetIntegrationAllocation[],
    assetId: string,
    assetName: string
) => {
    const properties = [
        {
            id: assetId,
            depth: -1,
            name: assetName,
            objectType: AllocationObjectType.ASSET,
        },
        ...flatPropertyTree(propertyTree),
    ] as PropertyAllocationItem[];

    const newAllocations = properties.map((property, index) => {
        const key = index === 0 ? "assetId" : "propertyId";
        const allocation = allocations.find(
            (allocation) => allocation[key] === property.id
        );

        return {
            ...property,
            allocation: allocation?.percentage,
            objectType: property.objectType,
        };
    });

    return newAllocations;
};
