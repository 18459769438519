import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { AllocationObjectType } from "graphql-types/graphql";

import { PropertyAllocationContainer } from "./PropertyAllocationContainer";
import { SelectAllocationType } from "./SelectAllocationType";
import { usePropertyAllocationQuery } from "./usePropertyAllocationQuery";
import { DrawerField } from "../DrawerField";
import {
    ownershipsEquals100,
    PropertyAllocationEditItem,
} from "../drawerFields.helpers";
import { EditableFieldList } from "../EditableFieldList";

type Props = {
    id: string;
    assetName: string;
    assetId: string;
};

export const PropertyAllocationField = (props: Props) => {
    const { id, assetName, assetId } = props;

    const { t } = useTranslation();

    const { data, loading, updateAllocation } = usePropertyAllocationQuery(
        id,
        assetName,
        assetId
    );

    const [objectType, setObjectType] = useState(AllocationObjectType.ASSET);
    const [items, setItems] = useState<PropertyAllocationEditItem[]>([]);

    useEffect(() => {
        const allocatedObjectType = data
            .filter((item) => item.allocation)
            .map((item) => item.objectType)[0];

        const objectType = allocatedObjectType ?? AllocationObjectType.ASSET;

        setObjectType(objectType);
        setItems(
            data.map((item) => ({
                key: item.id,
                label: item.name,
                value: item.allocation ?? 0,
                objectType: item.objectType,
                isChecked:
                    Boolean(item.allocation) && item.objectType === objectType,
            }))
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading]);

    const handleEditItem = (key: string, value: number) => {
        setItems((prev) =>
            prev.map((item) => (item.key === key ? { ...item, value } : item))
        );
    };

    const handleCheckItem = (key: string) => {
        setItems((prev) =>
            prev.map((item) =>
                item.key === key
                    ? { ...item, isChecked: !item.isChecked }
                    : item
            )
        );
    };

    const handleSave = () => {
        const allocations = items
            .filter((item) => item.isChecked && item.objectType === objectType)
            .map((item) => ({
                identifier: item.key,
                percentage: item.value,
            }));

        setObjectType(objectType);

        updateAllocation({
            variables: {
                id,
                type: objectType,
                allocations,
            },
        });
    };

    const handleCancel = () => {
        const allocatedObjectType = data
            .filter((item) => item.allocation)
            .map((item) => item.objectType)[0];

        const objectType = allocatedObjectType ?? AllocationObjectType.ASSET;

        setObjectType(objectType);
        setItems((prev) =>
            prev.map((item, index) => ({
                ...item,
                value:
                    item.objectType === objectType
                        ? data[index].allocation ?? 0
                        : 0,
                isChecked:
                    Boolean(item.value) && item.objectType === objectType,
            }))
        );
    };

    return (
        <DrawerField
            tooltip={t(
                "asset.sourceDrawer.propertyAllocationTooltip",
                "Changes to property allocation will trigger a recalculation of emissions data."
            )}
            label={t("common.labels.propertyAllocation", "Property Allocation")}
            isSaveEnabled={ownershipsEquals100(
                items.filter((item) => item.objectType === objectType)
            )}
            onSave={handleSave}
            onCancel={handleCancel}
        >
            <PropertyAllocationContainer data={data}>
                <SelectAllocationType
                    value={objectType}
                    onChange={setObjectType}
                />
                <EditableFieldList
                    items={items.filter(
                        (item) => item.objectType === objectType
                    )}
                    onCheckItem={handleCheckItem}
                    onEditItem={handleEditItem}
                />
            </PropertyAllocationContainer>
        </DrawerField>
    );
};
