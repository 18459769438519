import { MenuItem, Select } from "@mui/material";

import { AllocationObjectType } from "graphql-types/graphql";

type Props = {
    value: AllocationObjectType;
    onChange: (objectType: AllocationObjectType) => void;
};

export const SelectAllocationType = (props: Props) => {
    const { value, onChange } = props;

    return (
        <Select
            fullWidth
            size="small"
            variant="outlined"
            value={value}
            onChange={(e) => onChange(e.target.value as AllocationObjectType)}
        >
            {Object.values(AllocationObjectType).map((type) => (
                <MenuItem key={type} value={type}>
                    {type}
                </MenuItem>
            ))}
        </Select>
    );
};
