import { ApolloProvider } from "@apollo/client";
import * as Sentry from "@sentry/react";
import { Suspense } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import Overview from "components/PortfolioDashboard/Pages/Overview";
import YearlyBreakdown from "components/PortfolioDashboard/Pages/YearlyBreakdown";
import Toast from "components/Toastify/Toast";
import { ReportsFramework } from "containers/ReportsListPage/ReportsFramework";
import { ReportsSaved } from "containers/ReportsListPage/ReportsSaved";

import AssessmentRoute from "./Routing/AssessmentRoute";
import NavRoute from "./Routing/NavRoute";
import NoAuthRoute from "./Routing/NoAuthRoute";
import client from "../../utils/apolloClient";
import AssessmentOnboardingPage from "../AssessmentOnboarding/AssessmentOnboardingPage";
import AssetListPage from "../AssetListPage/AssetListPage";
import AssetPage from "../AssetPage";
import AuthPage from "../AuthPage/AuthPage";
import ChangeLogPage from "../ChangeLogPage/ChangeLogPage";
import CustomerImportPage from "../CustomerImportPage/CustomerImportPage";
import DataOverviewPage from "../DataOverviewPage/DataOverviewPage";
import DataUploadPage from "../DataUploadPage/DataUploadPage";
import ForecastPage from "../ForecastPage/ForecastPage";
import IntegrationOnboarding from "../IntegrationOnboarding/IntegrationOnboardingMain";
import InternalToolboxPage from "../InternalToolboxPage/InternalToolboxPage";
import NotFoundPage from "../NotFoundPage/NotFoundPage";
import PortfolioPage from "../PortfolioPage/PortfolioPage";
import ReportPage from "../ReportPage/ReportPage";
import ReportsListPage from "../ReportsListPage/ReportsListPage";
import SettingsPage from "../SettingsPage/SettingsPage";
import ThemePage from "../ThemePage/ThemePage";

const RoutesComponent = () => {
    const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

    return (
        <SentryRoutes>
            <Route path="auth" element={<NoAuthRoute />}>
                <Route path=":authType/:id?" element={<AuthPage />} />
            </Route>

            <Route element={<NavRoute />}>
                <Route
                    path="integration-onboarding"
                    element={<IntegrationOnboarding />}
                />
                <Route path="dev/theme" element={<ThemePage />} />

                <Route element={<AssessmentRoute />}>
                    <Route path="settings" element={<SettingsPage />} />
                    <Route path="change-log" element={<ChangeLogPage />} />

                    <Route
                        path="location-overview/:locationId/*"
                        element={<AssetPage />}
                    />
                    <Route path="forecast" element={<ForecastPage />} />
                    <Route path="assetlist" element={<AssetListPage />} />
                    <Route path="portfolio" element={<PortfolioPage />}>
                        <Route index element={<YearlyBreakdown />} />
                        <Route path="overview" element={<Overview />} />
                    </Route>
                    <Route
                        path="data-overview"
                        element={<DataOverviewPage />}
                    />
                    <Route path="reports" element={<ReportsListPage />}>
                        <Route index element={<ReportsFramework />} />
                        <Route path="saved" element={<ReportsSaved />} />
                    </Route>
                    <Route
                        path="reports/:reportType"
                        element={<ReportPage />}
                    />

                    <Route path="secret" element={<InternalToolboxPage />} />
                    <Route
                        path="internal-data-upload"
                        element={<DataUploadPage />}
                    />
                    <Route
                        path="internal-customer-onboarding"
                        element={<CustomerImportPage />}
                    />
                </Route>

                <Route path="/" element={<AssessmentOnboardingPage />} />
            </Route>

            <Route path="*" element={<NotFoundPage />} />
        </SentryRoutes>
    );
};

function App() {
    return (
        <Suspense>
            <Router>
                <ApolloProvider client={client}>
                    <RoutesComponent />

                    <Toast />
                </ApolloProvider>
            </Router>
        </Suspense>
    );
}

export default App;
