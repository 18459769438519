import { Box, Link, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import { getDataQualityTitle } from "components/DataQuality/DataQuality.helpers";
import { ConsumptionType, DataQualityTypes } from "graphql-types/graphql";
import {
    assessmentVerticalToTitle,
    getAssessmentCategoryTranslation,
} from "utils/assessment";
import { validateAndFormatDateString } from "utils/date.utils";

import { S } from "./dataSourceDrawer.styles";
import { AutomaticSourceRowData } from "../automaticSourcesTable.types";

type Props = {
    data: AutomaticSourceRowData;
};

export const SourceDetailsPanel = (props: Props) => {
    const { data } = props;
    const { t } = useTranslation();

    const details = [
        {
            label: t("common.labels.type", "Type"),
            data: data.type,
        },
        {
            label: t("common.labels.source", "Source"),
            data: data.source.source,
        },
        {
            label: t("common.labels.dataQuality", "Data Quality"),
            data: data.dataQuality
                ? getDataQualityTitle(
                      DataQualityTypes[data.dataQuality],
                      false,
                      t
                  )
                : "-",
        },
        {
            label: t("common.labels.address", "Address"),
            data: data.address,
        },
        {
            label: t(
                "common.labels.nationalPropertyId",
                "National Property ID"
            ),
            data: data.nationalPropertyId,
        },
        {
            label: t("common.labels.identifier", "Identifier"),
            data: data.identifier,
        },
        {
            label: t("common.labels.vertical", "Vertical"),
            data: data.vertical
                ? assessmentVerticalToTitle(data.vertical, t)
                : "-",
        },
        {
            label: t("common.labels.category", "Category"),
            data: data.consumptionType
                ? getAssessmentCategoryTranslation(
                      data.consumptionType as ConsumptionType,
                      t
                  )
                : "-",
        },
        // Not available yet, need to add it
        // {
        //     label: t("common.labels.supplier", "Supplier"),
        //     data: "Supplier",
        // },
        {
            label: t("common.labels.from", "From"),
            data: validateAndFormatDateString(data.from),
        },
        {
            label: t("common.labels.to", "To"),
            data: validateAndFormatDateString(data.to),
        },
        // Not available yet, need to add it
        {
            label: t("common.labels.documentation", "Documentation"),
            data: "Download",
            link: "http://google.com",
        },
    ];

    return (
        <S.Panel>
            {details.map(({ label, data, link }) => (
                <Box key={label} display="flex" justifyContent="space-between">
                    <Typography fontWeight="bold">{label}</Typography>
                    {link ? (
                        <Link href={link} target="_blank">
                            {data}
                        </Link>
                    ) : (
                        <Typography>{data}</Typography>
                    )}
                </Box>
            ))}
        </S.Panel>
    );
};
